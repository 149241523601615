<script>
  export let alt = undefined;
  export let src = undefined;
</script>

<div class="component">
  <div class="img-container">
    <img
      src="{src}"
      alt="{alt}"
    />
  </div>
  <div class="text"><slot></slot></div>
</div>

<style>
  .component {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .component .img-container {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid rgb(245, 166, 35);
    border-radius: 50%;
  }

  .component img {
    height: 40px;
  }

  .component .text {
    margin-top: 28px;
    font-size: 1rem;
    font-weight: bold;
    color: rgb(74, 74, 74);
  }
</style>