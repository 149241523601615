<script>
  import { video_is_playing } from "../../tools/stores.js";
  import UILinkButton from "../../ui-elements/UILinkButton.svelte";

  function updateVideoStatus() {
    video_is_playing.update(() => true);
  }
</script>

<div class="component container">
  <div class="row g-0 align-items-center">
    <div class="col-md-8">
      <div class="text-big">Mehr Bewegung,</div>
      <div class="text-big">Achtsamkeit und Konzentration.</div>
      <div class="text-big">Im Unterricht.</div>
      <div class="text-small">
        Effektiv für alle Schulkinder. Intuitiv für alle Lehrkräfte.
      </div>
      <div class="book-now">
        <UILinkButton
          href="#video"
          green
          style="padding: 20px 30px; font-size: 1.25rem; line-height: 1.25rem;"
          on:click={updateVideoStatus}
        >
          Video abspielen
        </UILinkButton>
      </div>
    </div>
    <div class="col-md-4 mt-3 mt-md-0">
      <img
        class="rotated-and-resized-image"
        src="/images/tablet_b2a_kids.png"
        alt="Laptop showing the team challenge app"
      />
    </div>
  </div>
</div>

<style>
  .component {
    padding: 0 20px;
    color: rgb(49, 49, 49);
  }

  .rotated-and-resized-image {
    transform: rotate(6deg);
  }

  .component .text-big {
    font-size: 50px;
    font-weight: bold;
    line-height: 56px;
    z-index: 12;
  }

  .component .text-small {
    font-size: 18px;
    margin-top: 8px;
    z-index: 12;
  }

  .component img {
    width: 100%;
    display: block;
    margin: 0 auto;
    z-index: 12;
  }

  .component .book-now {
    margin-top: 30px;
    text-transform: uppercase;
    z-index: 12;
  }
</style>
