<script>
  import { onMount } from "svelte";
  import { Link } from "svelte-routing";

  onMount(() => window.scrollTo(0, 0));
</script>

<div class="component">
  <div class="container">
    <div class="row back">
      <Link to="/">Zurück</Link>
    </div>
    <div class="row">
      <div>
        <h2>Impressum</h2>
        <br /><br />

        Back2Action GmbH<br />
        Tullastraße 11<br />
        68161 Mannheim<br /><br />

        <strong>Vertreten durch:</strong><br />
        Frau Birgit Bauer<br /><br />

        <strong>Kontakt:</strong><br />
        Telefon: +49 (0) 621 448954<br />
        E-Mail: kontakt(at)back2action.de<br />
        Registergericht: AG Mannheim <br />
        Handelsregister: HRB 729899<br />
        USt-IdNr. DE316722417<br /><br />

        <strong>Streitschlichtung</strong><br />
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor
        einer Verbraucherschlichtungsstelle teilzunehmen.
        <br /><br />
        <strong>Haftung für Inhalte</strong><br />
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
        Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG
        sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
        gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen,
        die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung
        oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
        bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab
        dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
        Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte
        umgehend entfernen.
        <br /><br /><strong>Haftung für Links</strong><br />
        Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte
        wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch
        keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets
        der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
        Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
        Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
        konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden
        von Rechtsverletzungen werden wir derartige Links umgehend entfernen. Haftung
        Teilnahme an den Back2Action Übungen Back2Action Birgit Bauer übernimmt keinerlei
        Haftung, wenn Sie als User durch die Teilnahme an den Back2Action Übungen
        zu Schaden kommen sollten. Bitte beachten Sie vor der Teilnahme an den Übungen:Wenn
        Sie sich über Ihren derzeitigen gesundheitlichen Zustand nicht sicher sind,
        kontaktieren Sie bitte Ihren Arzt oder Physiotherapeuten! Dies gilt insbesondere
        dann, wenn Sie unter akuten Schmerzen leiden. In diesem Fall sollte die Teilnahme
        an den Übungen nur auf ärztlichen Rat erfolgen. Bitte brechen Sie jede Übung
        sofort ab, wenn ein Schmerzempfinden auftritt. Bitte beachten Sie während
        der Teilnahme an den Übungen:Brechen Sie eine Übung sofort ab, wenn ein Schmerzempfinden
        eintritt.
        <br /><br /><strong>Urheberrecht</strong><br />
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
        unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung,
        Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes
        bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
        Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen
        Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber
        erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
        werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
        eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden
        Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
        umgehend entfernen.
      </div>
    </div>
  </div>
</div>

<style>
  .component {
    margin-top: 80px;
  }

  .component .back {
    margin-bottom: 20px;
  }
</style>
