<script>
  import { onMount } from "svelte";
  import { Link } from "svelte-routing";

  onMount(() => window.scrollTo(0, 0));
</script>

<div class="component">
  <div class="container">
    <div class="row back">
      <Link to="/">Zurück</Link>
    </div>
    <div class="row">
      <div>
        <h2>Datenschutz</h2>
        <br /><br />
        <h4>1. Datenschutz auf einen Blick</h4>
        <br /><br />
        <strong>Allgemeine Hinweise</strong><br />
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren
        personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene
        Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können.
        Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter
        diesem Text aufgeführten Datenschutzerklärung.
        <br /><br />

        <strong>Datenerfassung auf unserer Website</strong>
        <br /><br />
        <strong
          >Wer ist verantwortlich für die Datenerfassung auf dieser Website?</strong
        ><br />
        Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber.
        Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.
        <br /><br />
        <strong>Wie erfassen wir Ihre Daten?</strong><br />
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen.
        Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular
        eingeben. Andere Daten werden automatisch beim Besuch der Website durch unsere
        IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser,
        Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten
        erfolgt automatisch, sobald Sie unsere Website betreten.
        <br /><br />
        <strong>Wofür nutzen wir Ihre Daten?</strong><br />
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website
        zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens
        verwendet werden.
        <br /><br />
        <strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong><br />
        Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger
        und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben
        außerdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser Daten
        zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können
        Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
        Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde
        zu.

        <br /><br />
        <strong>Analyse-Tools und Tools von Drittanbietern</strong><br />
        Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet
        werden. Das geschieht vor allem mit Cookies und mit sogenannten Analyseprogrammen.
        Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten
        kann nicht zu Ihnen zurückverfolgt werden. Sie können dieser Analyse widersprechen
        oder sie durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte
        Informationen dazu finden Sie in der folgenden Datenschutzerklärung. Sie
        können dieser Analyse widersprechen. Über die Widerspruchsmöglichkeiten werden
        wir Sie in dieser Datenschutzerklärung informieren.
        <br /><br />
        <h4>2. Allgemeine Hinweise und Pflichtinformationen</h4>
        <br /><br />
        <strong>Datenschutz</strong><br />
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr
        ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend
        der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.<br
        /><br />
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten
        erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert
        werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten
        wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem
        Zweck das geschieht.<br /><br />
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der
        Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser
        Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
        <br /><br />

        <strong>Hinweis zur verantwortlichen Stelle</strong><br />
        Die verantwortliche Stelle gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung
        (DSGVO) für die Datenverarbeitung auf dieser Website ist:<br /><br />
        Back2Action GmbH<br />
        vertreten durch Birgit Bauer<br />
        Tullastr.11<br />
        68161 Mannheim<br /><br />
        Telefon: 0621/448954<br />
        E-Mail: kontakt(at)back2action.de<br /><br />
        Verantwortliche Stelle ist die natürliche oder juristische Person, die allein
        oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von
        personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
        <br />
        Wir sind gesetzlich nicht verpflichtet, einen Datenschutzbeauftragten gem.
        <a href="https://dsgvo-gesetz.de/art-37-dsgvo/">Art. 37 DSGVO</a>
        und
        <a href="https://dsgvo-gesetz.de/bdsg-neu/38-bdsg-neu/"
          >§ 38 BDSG (neu)</a
        >
        zu benennen und haben diesen auch nicht freiwillig benannt.
        <br /><br />
        <strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong><br />
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung
        möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen.
        Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit
        der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        <br /><br />
        <strong>Recht auf Datenübertragbarkeit</strong><br />
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder
        in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen
        Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen.
        Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
        verlangen, erfolgt dies nur, soweit es technisch machbar ist.
        <br /><br />
        <strong>SSL- bzw. TLS-Verschlüsselung</strong><br />
        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
        Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als
        Seitenbetreiber senden, eine SSL-bzw. TLSVerschlüsselung. Eine verschlüsselte
        Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von “http://”
        auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die
        Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
        <br /><br />
        <strong>Auskunft, Sperrung, Löschung</strong><br />
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das
        Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen
        Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und
        ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu
        sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich
        jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
        <br /><br />

        <h4>3. Datenerfassung auf unserer Website</h4>
        <br /><br />
        "Server-Log-Dateien"<br />
        Der Provider der Seiten erhebt und speichert automatisch Informationen in
        so genannten "Server-Log-Dateien", die Ihr Browser automatisch an uns übermittelt.
        Dies sind:
        <br />
        Browsertyp und Browserversion<br />
        verwendetes Betriebssystem<br />
        Referrer URL<br />
        Hostname des zugreifenden Rechners<br />
        Uhrzeit der Serveranfrage<br />
        IP-Adresse
        <br /><br />
        Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.<br
        />
        Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die
        Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher
        Maßnahmen gestattet.
        <br /><br />
        <strong>Kontaktformular</strong><br />
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben
        aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten
        zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns
        gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter. Die
        Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt somit
        ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).
        Sie können diese Einwilligung jederzeit widerrufen. Dazu reicht eine formlose
        Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
        Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. Die von Ihnen im
        Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung
        auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für
        die Datenspeicherung entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer
        Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen
        – bleiben unberührt.
        <br /><br />

        <h4>4. Analyse Tools und Werbung</h4>
        <br /><br />
        <strong>Matomo (ehemals Piwik)</strong><br />
        Diese Website benutzt den Open Source Webanalysedienst Matomo. Matomo verwendet
        so genannte "Cookies". Das sind Textdateien, die auf Ihrem Computer gespeichert
        werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen.
        Dazu werden die durch den Cookie erzeugten Informationen über die Benutzung
        dieser Website auf unserem Server gespeichert. Die IP-Adresse wird vor der
        Speicherung anonymisiert. Matomo-Cookies verbleiben auf Ihrem Endgerät, bis
        Sie sie löschen.<br />
        Die Speicherung von Matomo-Cookies erfolgt auf Grundlage von Art. 6 Abs.
        1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der
        anonymisierten Analyse des Nutzerverhaltens, um sowohl sein Webangebot als
        auch seine Werbung zu optimieren. Die durch den Cookie erzeugten Informationen
        über die Benutzung dieser Website werden nicht an Dritte weitergegeben. Sie
        können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
        Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in
        diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich
        werden nutzen können. Wenn Sie mit der Speicherung und Nutzung Ihrer Daten
        nicht einverstanden sind, können Sie die Speicherung und Nutzung hier deaktivieren.
        In diesem Fall wird in Ihrem Browser ein Opt-Out-Cookie hinterlegt, der verhindert,
        dass Matomo Nutzungsdaten speichert. Wenn Sie Ihre Cookies löschen, hat dies
        zur Folge, dass auch das Matomo Opt-Out-Cookie gelöscht wird. Das Opt-Out
        muss bei einem erneuten Besuch unserer Seite wieder aktiviert werden.
        <br /><br />
        <strong>Google reCAPTCHA</strong><br />
        Wir nutzen “Google reCAPTCHA” (im Folgenden “reCAPTCHA”) auf unseren Websites.
        Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
        94043, USA (“Google”). Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe
        auf unseren Websites (z.B. in einem Kontaktformular) durch einen Menschen
        oder durch ein automatisiertes Programm erfolgt. Hierzu analysiert reCAPTCHA
        das Verhalten des Websitebesuchers anhand verschiedener Merkmale. Diese Analyse
        beginnt automatisch, sobald der Websitebesucher die Website betritt. Zur
        Analyse wertet reCAPTCHA verschiedene Informationen aus (z.B. IP-Adresse,
        Verweildauer des Websitebesuchers auf der Website oder vom Nutzer getätigte
        Mausbewegungen). Die bei der Analyse erfassten Daten werden an Google weitergeleitet.
        Die reCAPTCHA-Analysen laufen vollständig im Hintergrund. Websitebesucher
        werden nicht darauf hingewiesen, dass eine Analyse stattfindet.<br />
        Die Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO.
        Der Websitebetreiber hat ein berechtigtes Interesse daran, seine Webangebote
        vor missbräuchlicher automatisierter Ausspähung und vor SPAM zu schützen.
        Weitere Informationen zu Google reCAPTCHA sowie die Datenschutzerklärung
        von Google entnehmen Sie folgenden Links:
        <a target="blank" href="https://policies.google.com/privacy">
          https://policies.google.com/privacy
        </a>
        und
        <a target="blank" href="https://www.google.com/recaptcha">
          https://www.google.com/recaptcha
        </a>.
        <br /><br />
        <h4>5. Plugins und Tools</h4>
        <br /><br />
        <strong>Google Web Fonts</strong><br />
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte
        Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite
        lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte
        und Schriftarten korrekt anzuzeigen. Zu diesem Zweck muss der von Ihnen verwendete
        Browser Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt
        Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen
        wurde. Die Nutzung von Google Web Fonts erfolgt im Interesse einer einheitlichen
        und ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
        Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Wenn Ihr Browser Web
        Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.<br
        />
        Weitere Informationen zu Google Web Fonts finden Sie unter
        <a href="https://developers.google.com/fonts/faq">
          https://developers.google.com/fonts/faq
        </a>
        und in der Datenschutzerklärung von Google:
        <a href="https://policies.google.com/privacy">
          https://policies.google.com/privacy
        </a>.
      </div>
    </div>
  </div>
</div>

<style>
  .component {
    margin-top: 80px;
  }

  .component .back {
    margin-bottom: 20px;
  }
</style>
