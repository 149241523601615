<script>
  import { onMount } from "svelte";
  import { Link } from "svelte-routing";

  onMount(() => window.scrollTo(0, 0));
</script>

<div class="component">
  <div class="container">
    <div class="row back">
      <Link to="/">Zurück</Link>
    </div>
    <div class="row">
      <div>
        <h2>Urheberrecht</h2>
        <br /><br />
        Alle Rechte vorbehalten. Alle Inhalte der webasierten Anwendung Back2Action
        unterliegen dem Schutz des Urheberrechts und anderer Schutzgesetze. Soweit
        nicht anders angegeben, sind alle Markenzeichen dieser Internet-Präsenz markenrechtlich
        geschützt. (Video pexels-kindel-media (#6774777); Shutterstock (photo #1503921524))
      </div>
    </div>
  </div>
</div>

<style>
  .component {
    margin-top: 80px;
  }

  .component .back {
    margin-bottom: 20px;
  }
</style>
